import * as variables from './functions/_variables';
import * as layout from './functions/_layout';
import * as animation from './functions/_animation';
import * as offcanvas from './functions/_offcanvas';

// ==============================================================
// my code
// ==============================================================

// ------------------------------------------------------------
// グローバル変数・定数
// ------------------------------------------------------------

const SCREEN_LG   = 1250;
let   WINDOW_W = window.innerWidth;
let   WINDOW_H = window.innerHeight;
let   WINDOW_SC_POS = window.pageYOffset;
let   $ = jQuery.noConflict(); //wordpress標準のjqueryを読み込み
let   scrollPoint = 0;
let   lastPoint = 0;

$(function(){
  
  // ------------------------------------------------------------
  // 処理実行
  // ------------------------------------------------------------
  
  $(window).on('load',function(){
    
    if(WINDOW_W < SCREEN_LG){
      setGlobalNavItemWidth();
    }else{
    }
  
    // オフキャンバスメニュー初期化
    offcanvas.setOffcanvas();
    
    //ヘッダークラス付与処理
    setHeaderClass();
  
    //メインビジュアルスライダー関係処理
    setMainvisualSlider();
    
  });
  
  $(window).on('scroll',function(){
  
    // -------------------------
    // プリセット処理
    // -------------------------
    
    //スクロールポジションを更新
    WINDOW_SC_POS = $(window).scrollTop();
    
    // -------------------------
  
    //ヘッダークラス付与処理
    setHeaderClass();
    
    // フレキシブルヘッダー
    // ------------------------------------------------------------
    
    if($('body').hasClass('home') || $('body').hasClass('single')){
      flexibleHeader();
    }else{
      flexibleHeader();
      
      let pageHeaderH = $('#page_header_wrap').height();
      let $headerElm = $('.js_header');
      
      
      if(WINDOW_SC_POS > pageHeaderH){
        $headerElm.addClass('js_isTheme01');
        $headerElm.removeClass('js_isTheme02');
      }else{
        $headerElm.removeClass('js_isTheme01');
        $headerElm.addClass('js_isTheme02');
      }
    }
    
    function flexibleHeader(){
      
      scrollPoint = window.scrollY;
  
      if(scrollPoint > lastPoint){
        //下スクロール
        $('.js_header').addClass('js_isHidden');
        
      }else{
        // 上スクロール
        $('.js_header').removeClass('js_isHidden');
      }
  
      lastPoint = scrollPoint;
      
    }
  
  });
  
  
  $(window).on('resize',function (){
  
    // -------------------------
    // プリセット処理
    // -------------------------
    
    //画面幅更新
    WINDOW_W = $(window).width();
  
    // -------------------------
    
    if(WINDOW_W < SCREEN_LG){
      resetGlobalChildNavWidth();
    }else{
    }
  
    //オフキャンバスメニューリセット処理
    offcanvas.setOffcanvas('reset');
    
  });
  
});

// ==============================================================
// functions
// ==============================================================

// PC時グローバルナビの子メニューに対して横幅を持たせる
// ------------------------------------------------------------

function setGlobalNavItemWidth(){
  $(".global_nav-item.is_parent").on({
    "mouseenter": function(){
      if(WINDOW_W > SCREEN_LG) {
        if ($(this).find('.global_nav_child-body').length) {
          $(this).find('.global_nav_child-body').width($(this).width());
        }
      }
    },
    "mouseleave": function(){
      return false;
    }
  });
}

// リセット処理 > PC時グローバルナビの子メニューに対して横幅を持たせる
// ------------------------------------------------------------

function resetGlobalChildNavWidth(){
  $('.global_nav_child-body').css('width','auto');
}

// スクロール量に応じて、ヘッダーのスタイル変更を行う
// ------------------------------------------------------------

function setHeaderClass(){
  
  let $header = $('.js_header');
  
  //トップページ固有処理
  if($('.js_main_visual').length){
  
    let entryPosition = $('.js_main_visual').height() + $('.js_main_visual').offset().top;
    
    if(WINDOW_SC_POS > entryPosition){
      $header.addClass('is_haeder_style02');
    }else{
      if($header.hasClass('is_haeder_style02')){
        $header.removeClass('is_haeder_style02');
      }
    }
    
  }else{
  
    //下層ページ固有処理
  
  }
  
}

// メインビジュアルスライダー関係処理
// ------------------------------------------------------------

function setMainvisualSlider(){
  
  //要素指定
  let $slick = $('.js_main_visual_slider');
  
  //初期化イベント処理
  $slick.on('init', function(slick){
  
    let $sliderContainer = $('.main_visual_slider');
    let sliderContainerH = $sliderContainer.height();
    
    if(WINDOW_H < $sliderContainer.height()){
      let headerH = $('.js_header').height();
      let contentBetween = sliderContainerH - WINDOW_H + headerH;
      console.log(contentBetween);
      $sliderContainer.css('margin-top',contentBetween * -1)
    }
    
    $('.js_main_visual').addClass('is_slider_loaded');
    
  });
  
  //スライダー初期化
  $slick.slick({
    fade:true,
    speed:3000,
    dots:false,
    arrows:false,
    autoplay:true,
    autoplaySpeed:2000,
    pauseOnHover:false,
    pauseOnFocus:false
  });


}

