
/*

README
オフキャンバスメニュー処理

HTMLには以下のクラスを付与するようにしてください

.js_offcanvas_body = オフキャンバスメニューのボディ要素となるもの
js_offcanvas_wrapper = オフキャンバスメニューの親要素となるもの（headerタグを水晶）

オフキャンバスメニュー展開時には「js_offcanvas_wrapper」クラスを付与した要素に
「is_offcanvas_open」というクラスが付与され、CSSのスタイリングにより開閉アニメーションが実行されます

*/

let   $ = jQuery.noConflict(); //wordpress標準のjqueryを読み込み

export function setOffcanvas(mode) {

//DOMを変数に格納
  var $bodyElm = $('.js_offcanvas_body');
  var $headerElm = $('.js_offcanvas_wrapper');
  var $bodyEml = $('body');
  
  var openClassName = 'is_offcanvas_open';
  var bgLayerHtml= '<div class="js_offcanvas_background_layer"></div>';
  var currentScrollPos = null;

  // オフキャンバス初期化処理
  // ------------------------------------------------------------
  
  function initOffcanvas() {
  
    //背景レイヤーを生成
    $bodyElm.after(bgLayerHtml);
  
    //クリックイベント処理
    $('.js_offcanvas_btn,.js_offcanvas_background_layer').on('click', function () {
    
      if ($headerElm.hasClass(openClassName)) {
        $headerElm.removeClass(openClassName);
        fixedScrollPosition(true);
      } else {
        $headerElm.addClass(openClassName);
        fixedScrollPosition(false);
      }
    });
  }

  // 背景要素スクロール位置固定処理
  // ------------------------------------------------------------
    
  function fixedScrollPosition(reset) {
    
    if (reset === false) {
      
      //現在のスクロール量を取得
      currentScrollPos = $(window).scrollTop()
      
      //body要素を強制的にずらす
      $bodyEml.css({
        position: 'fixed',
        width: '100%',
        top: -1 * currentScrollPos
      });
      
    } else {
      
      //body要素のスタイルをリセット
      $bodyEml.css({
        position: 'static',
        width: 'auto',
        top: '0'
      });
      
      //元のスクロール位置に戻す
      $("html,body").scrollTop(currentScrollPos);
    }
  }

  // オフキャンバスメニュー強制リセット処理
  // ------------------------------------------------------------
    
  function resetOffcanvas() {
    if ($headerElm.hasClass(openClassName)) {
      $headerElm.removeClass(openClassName);
      fixedScrollPosition(true);
    }
  }
  
  // デバッグモード
  // ------------------------------------------------------------
  
  function doDebugMode(){
    $headerElm.addClass(openClassName);
    $bodyElm.after(bgLayerHtml);
  }
  
  // 呼び出し処理
  // ------------------------------------------------------------
  
  if(mode === 'reset') {
    resetOffcanvas();
  }else if(mode === 'debug'){
    doDebugMode();
  }else{
    initOffcanvas();
  }
  
}